import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
// import {checkthemeComponent} from './pages/checktheme/checktheme.Component'
const routes: Routes = [
  { path: '', redirectTo: 'apple', pathMatch: 'full' },
  { path: '**', component: HomeComponent },
  { path: ':any', component: HomeComponent },
  { path: 'campaign', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'home/:id', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
