import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { RestService } from '../../rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    contact_form,
    current_page,
    contact_form_field,
    contactFormValidation,
    campaign,
    button_name,
    contact_form_button_campaign,
    send_id,
    country,
    list,
    user_id,
    utm_source,
    form_type,
}

@Component({
    selector: 'app-contactformdialog',
    templateUrl: './campaigncontactformdialog.component.html',
    styleUrls: ['./campaigncontactformdialog.component.css']
})
export class CampaigncontactformdialogComponent implements OnInit {

    @ViewChild('fileInput') fileInput: ElementRef;
    fileAttr = 'Choose File';
    public utm_source:any;
    public user_id:any;
    public list:any;
    public country:any;
    public send_idd: any;
    public popupform_userdata:any;
    public contact_form: any;
    public contact_form_field: any;
    public campaign: any;
    public current_page: any;
    public contactFormValidationcamp: any;
    public button_name:any;
    public gmail_id:any;
    public contact_form_button_campaign:any;
    public empty:any = '';
    public isButtonLoading: boolean = false;
    public form_type: any;
    public preview_url: any = this.restApi.preview_url;

    contactForm = new FormGroup({
        first_name: new FormControl(''),
        last_name: new FormControl(''),
        contact: new FormControl(''),
        gender: new FormControl(''),
        email: new FormControl(''),
        company: new FormControl(''),
        address: new FormControl(''),
        language: new FormControl(''),
        allow: new FormControl(''),
        description: new FormControl(''),
        date_of_birth: new FormControl(''),
        marital_status: new FormControl(''),
        education: new FormControl(''),
        aadhaar_card_no: new FormControl(''),
        salary: new FormControl(''),
        job_title: new FormControl(''),
        company_address: new FormControl(''),
        image: new FormControl(''),
    });

    constructor(
        public restApi: RestService,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<CampaigncontactformdialogComponent>
    ) {
        this.campaign = this.data.campaign;
        this.current_page = this.data.current_page;
        this.contact_form = this.data.contact_form;
        this.contact_form_field = this.data.contact_form_field
        this.contactFormValidationcamp = this.data.contactFormValidation
        this.button_name = this.data.button_name;
        this.contact_form_button_campaign = this.data.contact_form_button_campaign;
        this.send_idd = this.data.send_id;
        this.country = this.data.country;
        this.list = this.data.list;
        this.user_id = this.data.user_id;
        this.utm_source = this.data.utm_source;
        this.form_type = this.data.form_type;

    }

    ngOnInit(): void {
        this.get_form_autofill();
    }
     async get_form_autofill(){
        this.popupform_userdata = await JSON.parse(localStorage.getItem('popupform_userdata'));
    }
    
    uploadFileEvt(imgFile: any) {
        if (imgFile.target.files && imgFile.target.files[0]) {
            this.fileAttr = '';

            Array.from(imgFile.target.files).forEach((file: File) => {
                this.fileAttr += file.name + ' - ';
            });

            let reader = new FileReader();
            reader.onload = (e: any) => {
                let image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    let imgBase64Path = e.target.result;
                   
                    this.contactForm.get("image").setValue(imgBase64Path, { emitEvent: false });
                };
            };
            reader.readAsDataURL(imgFile.target.files[0]);

            // Reset if duplicate image uploaded again
            this.fileInput.nativeElement.value = "";
        } else {
            this.fileAttr = 'Choose File';
        }
    }

    submitForm() {
        
        var all_value = this.contactForm.value;
        var status = 0;

        if (this.contactFormValidationcamp.first_name == true) {
            if (all_value.first_name == '') {
                this.contactFormValidationcamp.first_name_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.first_name_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.last_name == true) {
            if (all_value.last_name == '') {
                this.contactFormValidationcamp.last_name_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.last_name_error_msg = "";
            }
        }
        
        if (this.contactFormValidationcamp.contact == true) {
            if (all_value.contact == null || all_value.contact == '') {
                this.contactFormValidationcamp.contact_error_msg = "Required field.";
                status++;

            }else {

                this.contactFormValidationcamp.contact_error_msg = "";
            }
        }

        if(all_value.contact != null || all_value.contact != ''){
            if(!this.CheckNumber(all_value.contact)){
                this.contactFormValidationcamp.contact_error_msg = "Please enter your correct 10 digit number";
                status++;  
            }else{
                this.contactFormValidationcamp.contact_error_msg = "";
            }
        }

        if (this.contactFormValidationcamp.gender == true) {
            if (all_value.gender == '') {
                this.contactFormValidationcamp.gender_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.gender_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.email == true) {
            if (all_value.email == '') {
                this.contactFormValidationcamp.email_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.email_error_msg = "";
            }
        }

        if(all_value.email != '') {
            if(!this.CheckEmail(all_value.email)) {
                this.contactFormValidationcamp.email_error_msg = "Please enter right email address";
                status++;  
            }else{
                this.contactFormValidationcamp.email_error_msg = "";
            }
        }

        if (this.contactFormValidationcamp.company == true) {
            if (all_value.company == '') {
                this.contactFormValidationcamp.company_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.company_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.address == true) {
            if (all_value.address == '') {
                this.contactFormValidationcamp.address_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.address_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.language == true) {
            if (all_value.language == '') {
                this.contactFormValidationcamp.language_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.language_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.allow == true) {
            if (all_value.allow == '') {
                this.contactFormValidationcamp.allow_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.allow_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.description == true) {
            if (all_value.description == '') {
                this.contactFormValidationcamp.description_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.description_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.date_of_birth == true) {
            if (all_value.date_of_birth == '') {
                this.contactFormValidationcamp.date_of_birth_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.date_of_birth_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.marital_status == true) {
            if (all_value.marital_status == '') {
                this.contactFormValidationcamp.marital_status_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.marital_status_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.education == true) {
            if (all_value.education == '') {
                this.contactFormValidationcamp.education_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.education_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.aadhaar_card_no == true) {
            if (all_value.aadhaar_card_no == '') {
                this.contactFormValidationcamp.aadhaar_card_no_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.aadhaar_card_no_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.salary == true) {
            if (all_value.salary == '') {
                this.contactFormValidationcamp.salary_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.salary_error_msg = "";
            }
        }
        if (this.contactFormValidationcamp.company_address == true) {
            if (all_value.company_address == '') {
                this.contactFormValidationcamp.company_address_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.company_address_error_msg = "";
            }
        }

        if (this.contactFormValidationcamp.job_title == true) {
            if (all_value.job_title == '') {
                this.contactFormValidationcamp.job_title_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.job_title_error_msg = "";
            }
        }
        
        if (this.contactFormValidationcamp.image == true) {
            if (all_value.image == '') {
                this.contactFormValidationcamp.image_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationcamp.image_error_msg = "";
            }
        }

        if (status > 0) {
            return false;
        }

        this.submitFormApi(all_value);
    }

    submitFormApi(postData = null) {

        this.isButtonLoading = true;
        postData.campaign_id = this.campaign.form_id;
        if(this.current_page?.form_id != "" && this.current_page?.form_id == 'undefined'){
            postData.page_id = this.current_page.form_id;
        }
        postData.send_id = this.send_idd;
        postData.list = this.list;
        postData.country = this.country;
        postData.contact_form = this.contact_form.form_id;
        postData.user_id = this.user_id;
        postData.utm_source = this.utm_source;
        this.restApi.postData({ apikey: this.restApi.API_KEY, form_data:postData }, 'submit_contact_form').subscribe(response2 => {
            if(postData.email != ""){
                
                if(postData.email != ""){
                    localStorage.setItem('dotconversUserEmail', postData.email);
                } 
            }
            if (response2.responseStatus = 1) {
                let snackBarRef = this._snackBar.open(response2.responseMessage, 'OK', {
                    duration: 3000
                });
            }
            else if (response2.responseStatus == 2) {
                let snackBarRef = this._snackBar.open(response2.responseMessage, 'OK', {
                    duration: 3000
                });
            }
            this.dialogRef.close('Optional Data');
        })
    }

    CheckNumber(number) {
        if(number.toString().length == 10){
            return true;
        }else{
            return false;
        }
    }

    CheckEmail(email) {

        var pattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/; 
        
        if(email.match(pattern)) {
            return true;
        }else{
            return false;
        }
    }
}
