import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpClientModule } from '@angular/common/http';

@Injectable({
    providedIn: 'root'

})
export class RestService {
    
    public API_KEY:any = "2992c9debd79d95734hj89234hx234";
    // public manu_icon_default:any = "http://localhost/dotconverse/front/icon_image/"; 
    // public assets_url:any = 'http://localhost/dotconverse/upload/documents/';
    // public BaseUrl:any = 'http://localhost/dotconverse/api/';
    // public pdf_url:any = 'http://localhost/dotconverse/front/pdf/';
    // public preview_url:any = 'http://localhost/dotconverse/front/template_preview_image/';


    public manu_icon_default:any = "https://mk.linkinfotech/front/icon_image/"; 
    public BaseUrl:any = 'https://mk.linkinfotech/api/';
    public assets_url:any = 'https://mk.linkinfotech/upload/documents/';
    public pdf_url:any = 'https://mk.linkinfotech/front/pdf/';
    public preview_url:any = 'https://mk.linkinfotech/front/template_preview_image/';

    constructor(
        private http: HttpClient
        ) {

    }

    postData(data: any, method: any): Observable<any> {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');
        
        // console.log(data, method);
        return this.http.post<any>(this.BaseUrl + method, JSON.stringify(data), {headers:headers}).pipe(
            tap((data) => console.log()
            ),
            catchError(this.handleError<any>('Login'))
        );
    }
    

    private handleError<T>(operation = 'operation', result?: T) {

        return (error: any): Observable<T> => {
           
            // alert("Server error/Internet connectivity")
            console.error(error); // log to console instead
            console.log('Failed :: while hit service due to internet or server occurrence');
            return of(result as T);
        };
    }



}
