import { Component, OnInit, ViewChild, ElementRef, Inject, PipeTransform, Pipe , HostListener,Renderer2} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { RestService } from '../../rest.service';
import { DomSanitizer, SafeResourceUrl, Title, BrowserModule, Meta } from '@angular/platform-browser';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactformdialogComponent } from '../contactformdialog/contactformdialog.component';
import { CampaigncontactformdialogComponent } from '../campaigncontactformdialog/campaigncontactformdialog.component';
import { CookieService } from 'ngx-cookie-service';
// import { ScriptService } from "./services/script.service";
import * as $ from 'jquery';



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {

    @Pipe({ name: 'safeHtml' })
    @ViewChild('fileInput') fileInput: ElementRef;
    fileAttr = 'Choose File';

    contactForm = new FormGroup({
        first_name: new FormControl(''),
        last_name: new FormControl(''),
        contact: new FormControl(''),
        gender: new FormControl(''),
        email: new FormControl(''),
        company: new FormControl(''),
        address: new FormControl(''),
        language: new FormControl(''),
        allow: new FormControl(''),
        description: new FormControl(''),
        date_of_birth: new FormControl(''),
        marital_status: new FormControl(''),
        education: new FormControl(''),
        aadhaar_card_no: new FormControl(''),
        salary: new FormControl(''),
        company_address: new FormControl(''),
        job_title: new FormControl(''),
        image: new FormControl(''),
    });

    public contactFormValidation = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        job_title:false,
        job_title_error_msg: '',
        image_error_msg: '',
    };

    public contactFormValidationnOriginal = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        image_error_msg: '',
        job_title: false,
        job_title_error_msg: '',
    };

    public contactFormValidationn = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        image_error_msg: '',
        job_title: false,
        job_title_error_msg: '',
    };

    public contactFormValidationcamp = {
        first_name: false,
        first_name_error_msg: '',
        last_name: false,
        last_name_error_msg: '',
        contact: false,
        contact_error_msg: '',
        gender: false,
        gender_error_msg: '',
        email: false,
        email_error_msg: '',
        company: false,
        company_error_msg: '',
        address: false,
        address_error_msg: '',
        language: false,
        language_error_msg: '',
        allow: false,
        allow_error_msg: '',
        description: false,
        description_error_msg: '',
        date_of_birth: false,
        date_of_birth_error_msg: '',
        marital_status: false,
        marital_status_error_msg: '',
        education: false,
        education_error_msg: '',
        aadhaar_card_no: false,
        aadhaar_card_no_error_msg: '',
        salary: false,
        salary_error_msg: '',
        company_address: false,
        company_address_error_msg: '',
        image: false,
        image_error_msg: '',
        job_title : false,
        job_title_error_msg : '',
    };
    public pageuniqueId:any;
    public renderer: any;
    public submite_camp_btn: any;
    public time: any;
    public query_type: any;
    public user_email: any;
    public campaign_id: any;
    public campaign_id_send: any;
    public page_id_send: any;
    public BaseUrl: any = this.restApi.BaseUrl
    public assets_url: any = this.restApi.assets_url;
    public manu_icon_default: any = this.restApi.manu_icon_default;
    public pdf_url: any = this.restApi.pdf_url;
    public a: any;
    public campaign: any;
    public contact_form: any;
    public contact_form_field: any;
    public current_page: any = [];
    public all_pages: any = [];
    public responseData: any = [];
    public embeded_video_url: SafeResourceUrl;
    public embeded_page_url: SafeResourceUrl;
    public isLoading = true;
    public prev_page: any = -1;
    public next_page: any = 1;
    public total_page: any = 0;
    public isButtonLoading: boolean = false;
    public time_insert_time_id: any;
    public clock: any;
    public timerr: any = 0;
    public display: any;
    public form_position: any;
    public gmail_id: any;
    public gmail_idd: any;
    public banner_data: any;
    public year: any;
    public user_type: any;
    public banner_image: any;
    public banner_title: any;
    public baner_url: any;
    public contact_form_campaign: any;
    public contact_form_field_campaign: any;
    public contact_form_button_campaign: any;
    public popup_form_btn: any;
    public submite_button_color: any;
    public newstr: any;
    public popupform_userdata: any;
    public empty: any = '';
    public menu: any;
    public previous_menu: any;
    public next_menu: any;
    public tmp_id: any;
    public email_template_id: any;
    public text_editer: any;
    public email_template: any;
    public sanitized_html: any;
    public sanitized_htmlcss: any;
    public sanitized_htmlxxx: any;
    public script:any;
    public xyz: any;
    public send_id :any;
    public send_idd : any;
    public sendd_id : any;
    public utm_source: any;
    public utm_sourcee: any;
    public utm_sourcee_id:any;
    public previousScroll :any;
    public last_hit:any;
    public scrollHeight:any;
    public clientHeight:any; 
    public scoll_hit = 0;
    public howmuchtime:any;
    public clientHeightt:any;
    public scrollHeightt:any;
    public Subdomain:any;
    public salt:any;
    public CheckSalt:any;
    public salt_id:any;
    public interval: any;
    loaderview: boolean = false;
    public form_type:any;
    public formwidth:any;
    public formheight:any;
    public form_width:any;
    public form_height:any;
    public formtype:any;
    public preview_url: any = this.restApi.preview_url;
    public campaign_form_id:any;
    public timer:any;

    constructor(
        public renderer2: Renderer2,
        public restApi: RestService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public sanitizer: DomSanitizer,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        private Cookie: CookieService,
        public meta: Meta,
        public title: Title,
        public sanitized: DomSanitizer,
        @Inject(DOCUMENT)
        private _document: Document
    ) {

        this.activatedRoute.pathFromRoot[1].url.subscribe(val => {
            this.Subdomain = location.origin;
            this.campaign_id = val[0].path;
            this.menu = val[1]?.path;
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                this.utm_source = params.utm_source;
                if (this.utm_source === undefined) {
                    this.utm_sourcee = localStorage.getItem(this.campaign_id+'-utm_source');
                    if (this.utm_sourcee != "") {
                        this.utm_sourcee_id = this.utm_sourcee;
                    } else {
                        this.utm_sourcee_id = "";
                    }
                } else {
                    this.utm_sourcee_id = this.utm_source;
                    localStorage.setItem(this.campaign_id+'-utm_source', this.utm_source);

                }

                this.tmp_id = params.tmp_id;
                if (this.tmp_id === undefined) {
                    this.email_template = localStorage.getItem('dotconversEmailTemplate');
                    if (this.email_template != "") {
                        this.email_template_id = this.email_template;
                    } else {
                        this.email_template_id = "";
                    }
                } else {
                    this.email_template_id = this.tmp_id;
                    localStorage.setItem('dotconversEmailTemplate', this.email_template_id);
                }

                this.salt = params.salt;
                if (this.salt === undefined) {
                    this.CheckSalt = localStorage.getItem('dotconversSalt');
                    if (this.CheckSalt != "" && this.CheckSalt != null) {
                        this.salt_id = this.CheckSalt;
                        this.user_type = "Known";
                    } else {
                        this.salt_id = "";
                        this.user_type = "Unknown";
                    }
                } else {
                    this.salt_id = this.salt;
                    this.user_type = "Known";
                    localStorage.setItem('dotconversSalt', this.salt_id);
                }

                this.send_id = params.send_id;
                if (this.send_id === undefined) {
                    this.sendd_id = localStorage.getItem('send_idd');
                    if (this.sendd_id != "" && this.sendd_id != null) {
                        this.send_idd = this.sendd_id;
                    } else {
                        this.send_idd = "";
                    }
                } else {
                    this.send_idd = this.send_id;
                    localStorage.setItem('send_idd', this.send_idd);
                }

                this.gmail_id = params.email_id;
                this.get_campaign_detail();
                if (this.gmail_id === undefined) {
                    this.gmail_idd = localStorage.getItem('dotconversUserEmail');
                    if (this.gmail_idd != "" && this.gmail_idd != null) {
                        this.gmail_id = this.gmail_idd;
                        this.startTimer();
                    } else {
                            
                            this.startTimer();
                    }
                } else {
                    localStorage.setItem('dotconversUserEmail', this.gmail_id);
                    this.startTimer();
                }
            });
        });
    }

    ngOnInit(): void {
        this.get_form_autofill(); 

        this.loaderview = true;
        this.startTimeloader();
    }

    get_form_autofill() {

        this.gmail_id = localStorage.getItem('dotconversUserEmail');
        this.restApi.postData({ apikey: this.restApi.API_KEY, gmail_id: this.gmail_id , salt : this.salt_id }, 'get_campaign_form_auto').subscribe(response2 => {
            if (response2.responseStatus == 1) {
                localStorage.setItem('popupform_userdata', JSON.stringify(response2.data));
                this.popupform_userdata = response2.data;
                localStorage.setItem('dotconversEmailTemplate', this.popupform_userdata?.email_template_id);
                localStorage.setItem('send_idd', this.popupform_userdata?.send_id);
                localStorage.setItem('dotconversUserEmail', this.popupform_userdata?.email_respon);
                this.gmail_id = this.popupform_userdata?.email_respon;
                this.send_idd = this.popupform_userdata?.send_id;
                this.email_template_id = this.popupform_userdata?.email_template_id;
            }
        })
    }

    get_campaign_detail() {
        this.restApi.postData({ apikey: this.restApi.API_KEY, campiagn_id: this.campaign_id, menu: this.menu , Subdomain: this.Subdomain }, 'get_campiagn_details').subscribe(response => {

            this.responseData = response;
            this.isLoading = false;
            if (response.responseStatus == 1) {
                this.campaign = response.data.campaign;
                this.campaign_form_id = this.campaign.form_id;
                
                if (this.campaign.front_template == 2) {

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme2/css/style.css';


                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme2/lib/font-awesome/css/font-awesome.min.css';


                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/theme2/lib/bootstrap/css/bootstrap.min.css';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/mobile-nav/mobile-nav.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/js/main.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/bootstrap/js/bootstrap.bundle.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/jquery/jquery-migrate.min.js';

                    var script = document.createElement('script');
                    script.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme2/lib/jquery/jquery.min.js';


                } else {
                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.rel = 'stylesheet';
                    link.id = 'widget_styles';
                    headID.appendChild(link);
                    link.href = 'assets/theme1/css/styles.css';

                    var linkk = document.createElement('link');
                    linkk.type = 'text/css';
                    linkk.rel = 'stylesheet';
                    linkk.id = 'widget_styles';
                    headID.appendChild(linkk);
                    linkk.href = 'assets/theme1/css/style.css';

                    var script = document.createElement('script');
                    linkk.id = 'widget_styles';
                    headID.appendChild(script);
                    script.src = 'assets/theme1/js/main.js';

                    var script = document.createElement('script');
                    headID.appendChild(script);
                    script.src = 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js';
                    script.integrity = 'sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM';
                    // script.crossorigin = 'anonymous';

                }
                this.title.setTitle(this.campaign.meta_title);
                this.meta.addTags([
                    { name: 'description', content: this.campaign.meta_description },
                    { name: 'keyword', content: this.campaign.meta_keyward },
                ]);
                if (this.campaign.favicon != "") {
                    this._document.getElementById('appIcon').setAttribute('href', this.assets_url + '/' + this.campaign.favicon);
                }

                this.pdf_url = this.campaign.customer_unique_url + '/pdf/';
                // this.pdf_url = "http://localhost/dotconverse/front/pdf/";
                this.newstr = this.campaign.campaign_title.toString().replace(" ", "-");
                this.all_pages = response.data.campaign_pages;
                this.total_page = this.all_pages?.length || 0;
               
                this.current_page = response.data.current_page;
                
                if (this.current_page.page_type === 'Editor' && this.current_page.page_editor != '') {
                    
                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';link.rel = 'stylesheet';
                    headID.appendChild(link);
                    link.href = 'assets/visualcomposer/custom.css';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilyrobotoregularsubsetgreek-extcyrillic-extgreeklatin-extvietnamesecyrilliclatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Roboto%3Aregular&#038;subset=greek-ext%2Ccyrillic-ext%2Cgreek%2Clatin-ext%2Cvietnamese%2Ccyrillic%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilylato300subsetlatin-extlatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Lato%3A300&#038;subset=latin-ext%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserrat500subsetcyrillic-extlatin-extvietnamesecyrilliclatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3A500&#038;subset=cyrillic-ext%2Clatin-ext%2Cvietnamese%2Ccyrillic%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilylato300subsetlatinlatin-ext-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Lato%3A300&#038;subset=latin%2Clatin-ext&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibrarybackgroundsimpledistbackgroundsimplebundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://mk.linkinfotech/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/backgroundSimple/dist/backgroundSimple.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibrarybackgroundvideoyoutubedistbackgroundvideoyoutubebundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://mk.linkinfotech/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/backgroundVideoYoutube/dist/backgroundVideoYoutube.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibraryimagefilterdistimagefilterbundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://mk.linkinfotech/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/imageFilter/dist/imageFilter.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserratregularsubsetcyrillic-extlatin-extvietnamesecyrilliclatin-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3Aregular&#038;subset=cyrillic-ext%2Clatin-ext%2Cvietnamese%2Ccyrillic%2Clatin&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:assetslibrarybackgroundzoomdistbackgroundzoombundlecss-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://mk.linkinfotech/wp/wp-content/plugins/visualcomposer/public/sources/assetsLibrary/backgroundZoom/dist/backgroundZoom.bundle.css?ver=40.0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserrat500subsetcyrilliccyrillic-extlatinlatin-extvietnamese-css';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3A500&#038;subset=cyrillic%2Ccyrillic-ext%2Clatin%2Clatin-ext%2Cvietnamese&#038;ver=0-180';

                    var headID = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.type = 'text/css';
                    link.id = 'vcv:assets:source:styles:httpsfontsgoogleapiscomcssfamilymontserrat700subsetcyrilliccyrillic-extlatinlatin-extvietnamese-css';
                    link.rel = 'stylesheet';
                    link.media = 'all';
                    headID.appendChild(link);
                    link.href = 'https://fonts.googleapis.com/css?family=Montserrat%3A700&#038;subset=cyrillic%2Ccyrillic-ext%2Clatin%2Clatin-ext%2Cvietnamese&#038;ver=0-180';

                    this.sanitized_html = this.sanitized.bypassSecurityTrustHtml(this.current_page.page_editor);
                }
                this.previous_menu = this.current_page.previous_menu;
                this.next_menu = this.current_page.next_menu;

                if (this.current_page.page_type === 'embeded_video_url') {
                    this.embeded_video_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.current_page.page_embeded_video_url);
                }

                if (this.campaign.campaign_form != "") {
                    this.get_campaign_form();
                }

                if (this.current_page.page_type === 'url') {
                    this.embeded_page_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.current_page.page_url);
                }

                if (this.current_page.contact_form != '' && this.current_page.contact_form !== 0) {
                    this.get_form();

                } else {
                    this.contact_form = [];
                    this.contact_form_field = [];
                }

                if (this.current_page.banner != '') {
                    this.get_banner();
                }

                if (this.current_page.contact_form_view_type == "modal" && this.current_page.contact_form != '' && this.current_page.contact_form != '0') {
                    setTimeout(() => {
                        this.openDialog();
                    }, 500);
                }
                
                if (this.current_page.form_position == "right") {
                    this.form_position = "offset-md-9 form_right";
                }

                if (this.current_page.form_position == "left") {
                    this.form_position = "form_left";
                }

                if (this.current_page.form_position == "center") {
                    this.form_position = "form_center";
                }
            }
            else if (response.responseStatus == 2) {
                // / do nothing
            }
            this.send_campaign_report();
            setTimeout(() => {
                this.howmuchtime = new Array();
                this.scrollHeight = document.documentElement.scrollHeight;
                this.clientHeight = document.documentElement.clientHeight;
                var let_count = Math.round(this.scrollHeight/this.clientHeight);
                for (let index = 1; index <= let_count; index++) {
                    this.howmuchtime.push(index*this.clientHeight);
                }
                
                this.firstscrollfunction();
            },1000);
        })
        
    }
    firstscrollfunction(){
        this.scrollHeightt = document.documentElement.scrollHeight;
        this.clientHeightt = document.documentElement.clientHeight;
        this.restApi.postData({ apikey: this.restApi.API_KEY, page_id: this.page_id_send, campaign_id: this.campaign_id_send, user_email: this.gmail_id, email_template_id: this.email_template_id, send_idd: this.send_idd, currentScroll:this.clientHeightt,total_page_height:this.scrollHeightt}, 'send_scroll_depth').subscribe(response2 => {
            if (response2.responseStatus == 1) {
               // this.howmuchtime.splice(0,1);     
            }
        })
    }

    @HostListener("document:scroll")
    scrollfunction(){
        
        var currentScroll = document.documentElement.scrollTop + document.documentElement.clientHeight;
        
        if (currentScroll > this.previousScroll){
            if(currentScroll > this.howmuchtime[0]){
                this.restApi.postData({ apikey: this.restApi.API_KEY, page_id: this.page_id_send, campaign_id: this.campaign_id_send, user_email: this.gmail_id, email_template_id: this.email_template_id, send_idd: this.send_idd, currentScroll:currentScroll,total_page_height:this.scrollHeight}, 'send_scroll_depth').subscribe(response2 => {
                    if (response2.responseStatus == 1) {
                         
                    }
                })
                this.howmuchtime.splice(0,1);
            }
        }
        this.previousScroll = currentScroll - document.documentElement.clientHeight;
    }

    ngAfterViewInit() {
        setTimeout(function () {
            $('.page_loader_class').addClass('fadeOut');
            document.querySelector('body').classList.remove('page-top');
        }, 700);
    }

    get_banner() {
        this.restApi.postData({ apikey: this.restApi.API_KEY, banner_id: this.current_page.banner }, 'get_banner').subscribe(response2 => {

            if (response2.responseStatus == 1) {
                this.banner_data = response2.data;
            } else {
                this.banner_data = [];
            }
        })

    }

    send_campaign_report() {

        this.time = "";
        this.page_id_send = this.current_page.form_id;
        this.campaign_id_send = this.current_page.campaign_id;
        this.user_email = this.gmail_id;
        this.pageuniqueId = this.current_page.pageuniqueId;
        
        if (this.time == "") {
            this.query_type = "insert";
        } else {
            this.query_type = "update";
        }

        this.restApi.postData({ apikey: this.restApi.API_KEY, page_id: this.page_id_send, campaign_id: this.campaign_id_send, user_email: this.user_email, total_time: this.time, query_type: this.query_type, page_tag: this.current_page.page_tag,utm_source:this.utm_sourcee_id, pageuniqueId: this.pageuniqueId,user_id:this.popupform_userdata?.form_id , salt : this.salt_id }, 'send_campaiagn_report').subscribe(response2 => {

            if (response2.responseStatus = 1) {
                this.time_insert_time_id = response2.data;
            }
            else if (response2.responseStatus == 2) {

            }
        })
    }

    open_campaign_form() {
        if(this.formtype != ''){ 
            const dialogRef = this.dialog.open(CampaigncontactformdialogComponent, {
                width: this.form_width,
                // height: this.form_height,
                data: {
                    contact_form_button_campaign: this.submite_camp_btn,
                    button_name: this.contact_form_button_campaign,
                    campaign: this.campaign,
                    contact_form: this.contact_form_campaign,
                    contact_form_field: this.contact_form_field_campaign,
                    contactFormValidation: this.contactFormValidationcamp,
                    send_id : this.send_idd,
                    country : this.popupform_userdata?.country,  
                    list : this.popupform_userdata?.list,
                    user_id : this.popupform_userdata?.form_id,
                    utm_source : this.utm_sourcee_id,
                    company_name:this.popupform_userdata?.company_name,
                    form_type:this.formtype,
                },
            });

            dialogRef.afterClosed().subscribe(result => {
                //console.log(`Dialog result: ${result}`);
            });
        }
    }

    get_form() {
        this.restApi.postData({ apikey: this.restApi.API_KEY, form_id: this.current_page.contact_form , gmail : this.gmail_id , campaign_id : this.campaign_form_id  }, 'get_form').subscribe(response2 => {

            if (response2.responseStatus == 1) {
              
                this.contact_form = response2.data?.form;
                this.submite_button_color = this.contact_form?.submite_button_color;
                this.popup_form_btn = this.contact_form?.submit_button_name;
                this.contact_form_field = response2.data?.form_field;
                
                this.form_type = this.contact_form.template_form;
                // this.form_type = 'top_image_form';
                if(this.form_type == 'right_image_form' || this.form_type == 'left_image_form'){
                    this.formwidth = '800px';
                    // this.formheight = '78.5%';
                }else if (this.form_type == 'top_image_form'){
                    this.formwidth = '450px';
                    // this.formheight = '88%';
                }
                this.setContactFormFieldValidation();
            }
            else if (response2.responseStatus == 2) {
                this.contact_form = [];
                this.contact_form_field = [];
                this.form_type = '';

            }
        })
    }

    get_campaign_form() {

        this.restApi.postData({ apikey: this.restApi.API_KEY, form_id: this.campaign.campaign_form , gmail : this.gmail_id , campaign_id : this.campaign_form_id }, 'get_form').subscribe(response2 => {

            if (response2.responseStatus == 1) {
                this.contact_form_campaign = response2.data?.form;
                this.contact_form_field_campaign = response2.data?.form_field;
                this.contact_form_button_campaign = this.contact_form_campaign.submit_button_name;
                this.submite_camp_btn = this.contact_form_campaign?.submite_button_color;

                this.formtype = this.contact_form_campaign.template_form;
                if(this.formtype == 'right_image_form' || this.formtype == 'left_image_form'){
                    this.form_width = '800px';
                    // this.form_height = '78.5%';
                }else if (this.formtype == 'top_image_form'){
                    this.form_width = '450px';
                    // this.form_height = '88%';
                }

                this.setContactFormFieldValidation_camp();
            }
            else if (response2.responseStatus == 2) {
                this.contact_form_campaign = [];
                this.contact_form_field_campaign = [];
                this.formtype = '';
            }
        })
    }

    openDialog() {
        if(this.form_type != ''){
            const dialogRef = this.dialog.open(ContactformdialogComponent, {
                width: this.formwidth,
                // height: this.formheight,
                data: {
                    submite_button_color: this.submite_button_color,
                    button_name: this.popup_form_btn,
                    campaign: this.campaign,
                    current_page: this.current_page,
                    contact_form: this.contact_form,
                    contact_form_field: this.contact_form_field,
                    contactFormValidation: this.contactFormValidationn,
                    send_id : this.send_idd,
                    country :this.popupform_userdata?.country,  
                    list :this.popupform_userdata?.list,
                    user_id : this.popupform_userdata?.form_id,
                    utm_source : this.utm_sourcee_id,
                    company_name:this.popupform_userdata?.company_name,
                    form_type:this.form_type,
                },
            });

            dialogRef.afterClosed().subscribe(result => {

            });
        }
    }

    setContactFormFieldValidation() {

        this.contactFormValidationn = JSON.parse(JSON.stringify(this.contactFormValidationnOriginal));

        if (this.contact_form_field && this.contact_form_field.length) {
            for (let index = 0; index < this.contact_form_field.length; index++) {

                if (this.contact_form_field[index].form_name == 'first_name' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.first_name = true;
                } else if (this.contact_form_field[index].form_name == 'last_name' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.last_name = true;
                } else if (this.contact_form_field[index].form_name == 'contact' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.contact = true;
                } else if (this.contact_form_field[index].form_name == 'gender' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.gender = true;
                } else if (this.contact_form_field[index].form_name == 'email' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.email = true;
                } else if (this.contact_form_field[index].form_name == 'company' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.company = true;
                } else if (this.contact_form_field[index].form_name == 'address' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.address = true;
                } else if (this.contact_form_field[index].form_name == 'language' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.language = true;
                } else if (this.contact_form_field[index].form_name == 'allow' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.allow = true;
                } else if (this.contact_form_field[index].form_name == 'description' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.description = true;
                } else if (this.contact_form_field[index].form_name == 'date_of_birth' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.date_of_birth = true;
                } else if (this.contact_form_field[index].form_name == 'marital_status' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.marital_status = true;
                } else if (this.contact_form_field[index].form_name == 'education' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.education = true;
                } else if (this.contact_form_field[index].form_name == 'aadhaar_card_no' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.aadhaar_card_no = true;
                } else if (this.contact_form_field[index].form_name == 'salary' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.salary = true;
                } else if (this.contact_form_field[index].form_name == 'company_address' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.company_address = true;
                } else if (this.contact_form_field[index].form_name == 'image' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.image = true;
                } else if (this.contact_form_field[index].form_name == 'job_title' && this.contact_form_field[index].is_required == "required") {
                    this.contactFormValidationn.job_title = true;
                }
                
            }
        }

    }

    uploadFileEvt(imgFile: any) {
        if (imgFile.target.files && imgFile.target.files[0]) {
            this.fileAttr = '';

            Array.from(imgFile.target.files).forEach((file: File) => {
                this.fileAttr += file.name + ' - ';
            });

            let reader = new FileReader();
            reader.onload = (e: any) => {
                let image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    let imgBase64Path = e.target.result;
                    
                    this.contactForm.get("image").setValue(imgBase64Path, { emitEvent: false });
                };
            };
            reader.readAsDataURL(imgFile.target.files[0]);

            // Reset if duplicate image uploaded again
            this.fileInput.nativeElement.value = "";
        } else {
            this.fileAttr = 'Choose File';
        }
    }

    setContactFormFieldValidation_camp() {

        if (this.contact_form_field_campaign && this.contact_form_field_campaign.length) {
            for (let index = 0; index < this.contact_form_field_campaign.length; index++) {
                
                if (this.contact_form_field_campaign[index].form_name == 'first_name' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.first_name = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'last_name' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.last_name = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'contact' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.contact = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'gender' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.gender = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'email' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.email = true;
                }
                else if (this.contact_form_field_campaign[index]?.form_name == 'company' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.company = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'address' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.address = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'language' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.language = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'allow' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.allow = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'description' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.description = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'date_of_birth' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.date_of_birth = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'marital_status' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.marital_status = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'education' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.education = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'aadhaar_card_no' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.aadhaar_card_no = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'salary' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.salary = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'company_address' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.company_address = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'image' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.image = true;
                }
                else if (this.contact_form_field_campaign[index].form_name == 'job_title' && this.contact_form_field_campaign[index].is_required == "required") {
                    this.contactFormValidationcamp.job_title = true;
                }
                
            }
        }
    }

    submitForm() {
        
        var all_value = this.contactForm.value;
        var status = 0;

        if (this.contactFormValidationn.first_name == true) {
            if (all_value.first_name == '') {
                this.contactFormValidationn.first_name_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.first_name_error_msg = "";
            }
        }
        if (this.contactFormValidationn.last_name == true) {
            if (all_value.last_name == '') {
                this.contactFormValidationn.last_name_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.last_name_error_msg = "";
            }
        }

        if (this.contactFormValidation.contact == true) {
            if (all_value.contact == null || all_value.contact == '') {
                this.contactFormValidation.contact_error_msg = "Required field.";
                status++;

            }else {

                this.contactFormValidation.contact_error_msg = "";
            }
        }

        if(all_value.contact != null || all_value.contact != ''){
            if(!this.CheckNumber(all_value.contact)){
                this.contactFormValidation.contact_error_msg = "Please enter your correct 10 digit number";
                status++;  
            }else{
                this.contactFormValidation.contact_error_msg = "";
            }
        }
        
        if (this.contactFormValidationn.gender == true) {
            if (all_value.gender == '') {
                this.contactFormValidationn.gender_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.gender_error_msg = "";
            }
        }
        if (this.contactFormValidationn.email == true) {
            if (all_value.email == '') {
                this.contactFormValidationn.email_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.email_error_msg = "";
            }
        }

        if(all_value.email != '') {
            if(!this.CheckEmail(all_value.email)) {
                this.contactFormValidation.email_error_msg = "Please enter right email address";
                status++;  
            }else{
                this.contactFormValidation.email_error_msg = "";
            }
        }

        if (this.contactFormValidationn.company == true) {
            if (all_value.company == '') {
                this.contactFormValidationn.company_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.company_error_msg = "";
            }
        }
        if (this.contactFormValidationn.address == true) {
            if (all_value.address == '') {
                this.contactFormValidationn.address_error_msg = "Required field.";
                // status++;

            }else {
                this.contactFormValidationn.address_error_msg = "";
            }
        }
        if (this.contactFormValidationn.language == true) {
            if (all_value.language == '') {
                this.contactFormValidationn.language_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.language_error_msg = "";
            }
        }
        if (this.contactFormValidationn.allow == true) {
            if (all_value.allow == '') {
                this.contactFormValidationn.allow_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.allow_error_msg = "";
            }
        }
        if (this.contactFormValidationn.description == true) {
            if (all_value.description == '') {
                this.contactFormValidationn.description_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.description_error_msg = "";
            }
        }
        if (this.contactFormValidationn.date_of_birth == true) {
            if (all_value.date_of_birth == '') {
                this.contactFormValidationn.date_of_birth_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.date_of_birth_error_msg = "";
            }
        }
        if (this.contactFormValidationn.marital_status == true) {
            if (all_value.marital_status == '') {
                this.contactFormValidationn.marital_status_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.marital_status_error_msg = "";
            }
        }
        if (this.contactFormValidationn.education == true) {
            if (all_value.education == '') {
                this.contactFormValidationn.education_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.education_error_msg = "";
            }
        }
        if (this.contactFormValidationn.aadhaar_card_no == true) {
            if (all_value.aadhaar_card_no == '') {
                this.contactFormValidationn.aadhaar_card_no_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.aadhaar_card_no_error_msg = "";
            }
        }
        if (this.contactFormValidationn.salary == true) {
            if (all_value.salary == '') {
                this.contactFormValidationn.salary_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.salary_error_msg = "";
            }
        }
        if (this.contactFormValidationn.company_address == true) {
            if (all_value.company_address == '') {
                this.contactFormValidationn.company_address_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.company_address_error_msg = "";
            }
        }
        if (this.contactFormValidationn.job_title == true) {
            if (all_value.job_title == '') {
                this.contactFormValidationn.job_title_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.job_title_error_msg = "";
            }
        }
        
        if (this.contactFormValidationn.image == true) {
            if (all_value.image == '') {
                this.contactFormValidationn.image_error_msg = "Required field.";
                status++;

            }else {
                this.contactFormValidationn.image_error_msg = "";
            }
        }
       
        if (status > 0) {
            return false;
        }
        
        this.contactForm.reset();
        this.submitFormApi(all_value);
    }

    submitFormApi(postData = null) {
        this.isButtonLoading = true;
        if (this.current_page?.form_id != "") {
            postData.page_id = this.current_page?.form_id;
        }
        postData.campaign_id = this.campaign.form_id;
        postData.contact_form = this.current_page.contact_form;
        postData.send_id = this.send_idd;
        postData.country = this.popupform_userdata?.country;
        postData.list= this.popupform_userdata?.list;
        postData.user_id = this.popupform_userdata?.form_id;
        postData.utm_source = this.utm_sourcee_id;
        this.restApi.postData({ apikey: this.restApi.API_KEY, form_data: postData }, 'submit_contact_form').subscribe(response2 => {
            if(postData.email != ""){
               
                localStorage.setItem('dotconversUserEmail', postData.email);
            }
            if (response2.responseStatus = 1) {
                if (this.current_page.page_type === 'PDF') {
                    window.open(this.BaseUrl + "download_pdf/download/" + this.current_page.page_pdf);
                }
                localStorage.setItem('dotconversUserEmail', this.gmail_id);
                this.isButtonLoading = false;
                let snackBarRef = this._snackBar.open(response2.responseMessage, 'OK', {
                    duration: 3000
                });
            }
            else if (response2.responseStatus == 2) {
                let snackBarRef = this._snackBar.open(response2.responseMessage, 'OK', {
                    duration: 3000
                });
            }
        })
    }

    banner_report(id) {
        
        this.restApi.postData({ apikey: this.restApi.API_KEY, page_name: this.current_page.manu_title, banner_id: id, page_id: this.current_page.form_id, campaign_id: this.campaign_id_send, user_email: this.gmail_id,send_id:this.send_idd }, 'banner_report').subscribe(response2 => {

            if (response2.responseStatus = 1) {

            }else if (response2.responseStatus == 2) {

            }
        })
    }

    startTimer() {
        this.interval = setInterval(() => {
            if (this.time === 0) {
                this.time++;
            } else {
                this.time++;
            }
            this.display = this.transform(this.time);

        }, 1000);
    }

    transform(time) {
        this.page_id_send = this.current_page.form_id;
        this.campaign_id_send = this.current_page.campaign_id;
        if (time > 1) {
            this.query_type = "update";
        } else {
            this.query_type = "insert";
        }
        this.time = time;
       
        this.restApi.postData({ apikey: this.restApi.API_KEY, page_id: this.page_id_send, campaign_id: this.campaign_id_send, user_email: this.gmail_id, total_time: this.time, query_type: this.query_type, page_tag: this.current_page?.page_tag, user_type: this.user_type, page_weight: this.current_page?.page_weight, email_template_id: this.email_template_id, job_department: this.popupform_userdata?.job_department, designation: this.popupform_userdata?.designation, company_name: this.popupform_userdata?.company_name, fname: this.popupform_userdata?.first_name, lname: this.popupform_userdata?.last_name , send_idd: this.send_idd , utm_source:this.utm_sourcee_id , country:this.popupform_userdata?.country , list:this.popupform_userdata?.list, user_id:this.popupform_userdata?.form_id, pageuniqueId: this.pageuniqueId , salt : this.salt_id}, 'send_campaiagn_report').subscribe(response2 => {

            // if (response2.responseStatus = 2) {
            //  this.time_insert_time_id = response2.data.time_add;  
            //  alert(this.time_insert_time_id) 
            // }
            // else if (response2.responseStatus == 2) {

            // }
        })
    }

    startTimeloader() {
        this.interval = setInterval(() => {
            if (this.timer === 0) {
                this.timer++;
            } else {
                this.timer++;
            }
            this.display = this.callBackFn();
        }, 15000);
    }

    callBackFn() {
        this.loaderview = false;
    }

    CheckNumber(number) {
        if(number.toString().length == 10){
            return true;
        }else{
            return false;
        }
    }

    CheckEmail(email) {

        var pattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;

        if(email.match(pattern)) {
            return true;
        }else{
            return false;
        }
    }

}
