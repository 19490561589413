<!-- <================================================ Simple Form Sectaion ==================================================== -->

<div *ngIf="form_type == 'simple_form'">
    <form *ngIf="contact_form && contact_form != null" [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">

        <h1 mat-dialog-title class="form_name">{{contact_form.name}}</h1>
        <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
        <h3 class="form_header">{{contact_form?.form_header}}</h3>

        <div mat-dialog-content>
            <div class="row">
                <div class="col-12">

                    <mat-input-container>
                        <div class="form-field example-container"
                            *ngFor="let form_field of contact_form_field; let i = index">

                            <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.first_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                <mat-hint class="error" *ngIf="contactFormValidation.first_name_error_msg != ''">
                                    {{contactFormValidation.first_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.last_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.last_name_error_msg != ''">
                                    {{contactFormValidation.last_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.contact"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.contact_error_msg != ''">
                                    {{contactFormValidation.contact_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.gender">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <p class="mat-hind-wrapper" *ngIf="contactFormValidation.gender_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.gender_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="email" matInput [formControl]="contactForm.controls.email"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.email_error_msg != ''">
                                    {{contactFormValidation.email_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_error_msg != ''">
                                    {{contactFormValidation.company_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.address"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.address_error_msg != ''">
                                    {{contactFormValidation.address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 "
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.language">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.language_error_msg != ''">
                                    {{contactFormValidation.language_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.allow">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.allow_error_msg != ''">
                                    {{contactFormValidation.allow_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'description'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                <mat-hint class="error" *ngIf="contactFormValidation.description_error_msg != ''">
                                    {{contactFormValidation.description_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input readonly matInput [matDatepicker]="picker"
                                    [formControl]="contactForm.controls.date_of_birth">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-hint class="error" *ngIf="contactFormValidation.date_of_birth_error_msg != ''">
                                    {{contactFormValidation.date_of_birth_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <p class="mat-hind-wrapper"
                                    *ngIf="contactFormValidation.marital_status_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.marital_status_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.education"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.education_error_msg != ''">
                                    {{contactFormValidation.education_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.aadhaar_card_no_error_msg != ''">
                                    {{contactFormValidation.aadhaar_card_no_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.salary"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.salary_error_msg != ''">
                                    {{contactFormValidation.salary_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_address_error_msg != ''">
                                    {{contactFormValidation.company_address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.job_title_error_msg != ''">
                                    {{contactFormValidation.job_title_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'image'"
                                class="example-full-width mb-10 upload-file">
                                <div>
                                    <mat-toolbar>
                                        <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                        <button type="button" mat-flat-button>Browse File</button>
                                    </mat-toolbar>

                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)"
                                        name="uploadFile" accept="image/*" />
                                </div>
                                <mat-hint class="error" *ngIf="contactFormValidation.image_error_msg != ''">
                                    {{contactFormValidation.image_error_msg}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </mat-input-container>
                </div>
            </div>
        </div>

        <div mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>No Thanks</button>

            <ng-container *ngIf="this.button_name != ''">
                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                    color="{{submite_button_color}}">{{this.button_name}}</button>
            </ng-container>

            <ng-container *ngIf="this.button_name == ''">
                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                    color="{{submite_button_color}}">Submit</button>
            </ng-container>
            <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button color="primary" disabled>Please wait...</button>
        </div>
    </form>
</div>

<!-- <================================================ Left Image Form Sectaion ==================================================== -->

<div *ngIf="form_type == 'left_image_form'">
    <form *ngIf="contact_form && contact_form != null" [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
        <div mat-dialog-content>
            <div class="row">

                <div class="col-6">
                    <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                </div>

                <div class="col-6" style="float: right;">

                    <h1 mat-dialog-title class="form_name">{{contact_form.name}}</h1>
                    <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                    <h3 class="form_header">{{contact_form?.form_header}}</h3>

                    <mat-input-container>
                        <div class="form-field example-container"
                            *ngFor="let form_field of contact_form_field; let i = index">

                            <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.first_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                <mat-hint class="error" *ngIf="contactFormValidation.first_name_error_msg != ''">
                                    {{contactFormValidation.first_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.last_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.last_name_error_msg != ''">
                                    {{contactFormValidation.last_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.contact"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.contact_error_msg != ''">
                                    {{contactFormValidation.contact_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.gender">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>

                                <p class="mat-hind-wrapper" *ngIf="contactFormValidation.gender_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.gender_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="email" matInput [formControl]="contactForm.controls.email"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.email_error_msg != ''">
                                    {{contactFormValidation.email_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_error_msg != ''">
                                    {{contactFormValidation.company_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.address"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.address_error_msg != ''">
                                    {{contactFormValidation.address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 "
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.language">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.language_error_msg != ''">
                                    {{contactFormValidation.language_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.allow">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.allow_error_msg != ''">
                                    {{contactFormValidation.allow_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'description'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                <mat-hint class="error" *ngIf="contactFormValidation.description_error_msg != ''">
                                    {{contactFormValidation.description_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input readonly matInput [matDatepicker]="picker"
                                    [formControl]="contactForm.controls.date_of_birth">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-hint class="error" *ngIf="contactFormValidation.date_of_birth_error_msg != ''">
                                    {{contactFormValidation.date_of_birth_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <p class="mat-hind-wrapper"
                                    *ngIf="contactFormValidation.marital_status_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.marital_status_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.education"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.education_error_msg != ''">
                                    {{contactFormValidation.education_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.aadhaar_card_no_error_msg != ''">
                                    {{contactFormValidation.aadhaar_card_no_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.salary"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.salary_error_msg != ''">
                                    {{contactFormValidation.salary_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_address_error_msg != ''">
                                    {{contactFormValidation.company_address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.job_title_error_msg != ''">
                                    {{contactFormValidation.job_title_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'image'"
                                class="example-full-width mb-10 upload-file">
                                <div>
                                    <mat-toolbar>
                                        <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                        <button type="button" mat-flat-button>Browse File</button>
                                    </mat-toolbar>

                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)"
                                        name="uploadFile" accept="image/*" />
                                </div>
                                <mat-hint class="error" *ngIf="contactFormValidation.image_error_msg != ''">
                                    {{contactFormValidation.image_error_msg}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </mat-input-container>

                    <div mat-dialog-actions align="end">
                        <button mat-raised-button mat-dialog-close>No Thanks</button>

                        <ng-container *ngIf="this.button_name != ''">
                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                color="{{submite_button_color}}">{{this.button_name}}</button>
                        </ng-container>

                        <ng-container *ngIf="this.button_name == ''">
                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                color="{{submite_button_color}}">Submit</button>
                        </ng-container>
                        <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button color="primary" disabled>Please wait...</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<!-- <================================================ Right Image Form Sectaion ==================================================== -->

<div *ngIf="form_type == 'right_image_form'">
    <form *ngIf="contact_form && contact_form != null" [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
        <div mat-dialog-content>
            <div class="row">
                <div class="col-6" style="float: left;">

                    <h1 mat-dialog-title class="form_name">{{contact_form.name}}</h1>
                    <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                    <h3 class="form_header">{{contact_form?.form_header}}</h3>

                    <mat-input-container>
                        <div class="form-field example-container"
                            *ngFor="let form_field of contact_form_field; let i = index">

                            <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.first_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                <mat-hint class="error" *ngIf="contactFormValidation.first_name_error_msg != ''">
                                    {{contactFormValidation.first_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.last_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.last_name_error_msg != ''">
                                    {{contactFormValidation.last_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.contact"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.contact_error_msg != ''">
                                    {{contactFormValidation.contact_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.gender">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <p class="mat-hind-wrapper" *ngIf="contactFormValidation.gender_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.gender_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="email" matInput [formControl]="contactForm.controls.email"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.email_error_msg != ''">
                                    {{contactFormValidation.email_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_error_msg != ''">
                                    {{contactFormValidation.company_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.address"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.address_error_msg != ''">
                                    {{contactFormValidation.address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 "
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.language">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.language_error_msg != ''">
                                    {{contactFormValidation.language_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.allow">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.allow_error_msg != ''">
                                    {{contactFormValidation.allow_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'description'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                <mat-hint class="error" *ngIf="contactFormValidation.description_error_msg != ''">
                                    {{contactFormValidation.description_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input readonly matInput [matDatepicker]="picker"
                                    [formControl]="contactForm.controls.date_of_birth">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-hint class="error" *ngIf="contactFormValidation.date_of_birth_error_msg != ''">
                                    {{contactFormValidation.date_of_birth_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <p class="mat-hind-wrapper"
                                    *ngIf="contactFormValidation.marital_status_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.marital_status_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.education"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.education_error_msg != ''">
                                    {{contactFormValidation.education_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.aadhaar_card_no_error_msg != ''">
                                    {{contactFormValidation.aadhaar_card_no_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.salary"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.salary_error_msg != ''">
                                    {{contactFormValidation.salary_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_address_error_msg != ''">
                                    {{contactFormValidation.company_address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.job_title_error_msg != ''">
                                    {{contactFormValidation.job_title_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'image'"
                                class="example-full-width mb-10 upload-file">
                                <div>
                                    <mat-toolbar>
                                        <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                        <button type="button" mat-flat-button>Browse File</button>
                                    </mat-toolbar>

                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)"
                                        name="uploadFile" accept="image/*" />
                                </div>
                                <mat-hint class="error" *ngIf="contactFormValidation.image_error_msg != ''">
                                    {{contactFormValidation.image_error_msg}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </mat-input-container>

                    <div mat-dialog-actions align="end">
                        <button mat-raised-button mat-dialog-close>No Thanks</button>

                        <ng-container *ngIf="this.button_name != ''">
                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                color="{{submite_button_color}}">{{this.button_name}}</button>
                        </ng-container>

                        <ng-container *ngIf="this.button_name == ''">
                            <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                                color="{{submite_button_color}}">Submit</button>
                        </ng-container>
                        <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button color="primary" disabled>Please wait...</button>
                    </div>
                </div>

                <div class="col-6">
                    <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                </div>

            </div>
        </div>
    </form>
</div>

<!-- <================================================ Top Image Form Sectaion ==================================================== -->

<div *ngIf="form_type == 'top_image_form'">
    <form *ngIf="contact_form && contact_form != null" [formGroup]="contactForm" (ngSubmit)="submitForm()" autocomplete="off">
        <div mat-dialog-content>
            <div class="row">

                <img src="{{preview_url}}{{contact_form.template_image}}" alt="Image" class="img-fluid">
                <div class="col-12">
                    <h1 mat-dialog-title class="form_name">{{contact_form.name}}</h1>
                    <h3 class="form_sub_title">{{contact_form?.sub_title}}</h3>
                    <h3 class="form_header">{{contact_form?.form_header}}</h3>

                    <mat-input-container>
                        <div class="form-field example-container"
                            *ngFor="let form_field of contact_form_field; let i = index">

                            <mat-form-field *ngIf="form_field.form_name == 'first_name'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.first_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.first_name != undefined ? popupform_userdata?.first_name : empty">

                                <mat-hint class="error" *ngIf="contactFormValidation.first_name_error_msg != ''">
                                    {{contactFormValidation.first_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'last_name'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.last_name"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.last_name != undefined ? popupform_userdata?.last_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.last_name_error_msg != ''">
                                    {{contactFormValidation.last_name_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'contact'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.contact"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.mobile_no_respon != undefined ? popupform_userdata?.mobile_no_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.contact_error_msg != ''">
                                    {{contactFormValidation.contact_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'gender' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.gender">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <p class="mat-hind-wrapper" *ngIf="contactFormValidation.gender_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.gender_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'email'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="email" matInput [formControl]="contactForm.controls.email"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.email_respon != undefined ? popupform_userdata?.email_respon : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.email_error_msg != ''">
                                    {{contactFormValidation.email_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.company_name != undefined ? popupform_userdata?.company_name : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_error_msg != ''">
                                    {{contactFormValidation.company_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'address'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.address"
                                    placeholder="Enter {{form_field.form_label}}"
                                    [ngModel]="popupform_userdata != undefined && popupform_userdata?.address != undefined ? popupform_userdata?.address : empty">
                                <mat-hint class="error" *ngIf="contactFormValidation.address_error_msg != ''">
                                    {{contactFormValidation.address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'language'" class="example-full-width mb-10 "
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.language">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.language_error_msg != ''">
                                    {{contactFormValidation.language_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'allow'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-select [formControl]="contactForm.controls.allow">
                                    <mat-option *ngFor="let option of form_field.options.split(',')" value="{{option}}">
                                        {{option}}</mat-option>
                                </mat-select>
                                <mat-hint class="error" *ngIf="contactFormValidation.allow_error_msg != ''">
                                    {{contactFormValidation.allow_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'description'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <textarea matInput [formControl]="contactForm.controls.description"></textarea>
                                <mat-hint class="error" *ngIf="contactFormValidation.description_error_msg != ''">
                                    {{contactFormValidation.description_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'date_of_birth'"
                                class="example-full-width mb-10 " appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input readonly matInput [matDatepicker]="picker"
                                    [formControl]="contactForm.controls.date_of_birth">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-hint class="error" *ngIf="contactFormValidation.date_of_birth_error_msg != ''">
                                    {{contactFormValidation.date_of_birth_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <div *ngIf="form_field.form_name == 'marital_status' && form_field.options != ''"
                                class="example-full-width mb-10 ">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <mat-radio-group [formControl]="contactForm.controls.marital_status">
                                    <mat-radio-button *ngFor="let option of form_field.options.split(',')"
                                        value="{{option}}">{{option}}</mat-radio-button>
                                </mat-radio-group>
                                <p class="mat-hind-wrapper"
                                    *ngIf="contactFormValidation.marital_status_error_msg != ''">
                                    <mat-hint class="error">
                                        {{contactFormValidation.marital_status_error_msg}}
                                    </mat-hint>
                                </p>
                            </div>

                            <mat-form-field *ngIf="form_field.form_name == 'education'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.education"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.education_error_msg != ''">
                                    {{contactFormValidation.education_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'aadhaar_card_no'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.aadhaar_card_no"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.aadhaar_card_no_error_msg != ''">
                                    {{contactFormValidation.aadhaar_card_no_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'salary'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="number" matInput [formControl]="contactForm.controls.salary"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.salary_error_msg != ''">
                                    {{contactFormValidation.salary_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'company_address'"
                                class="example-full-width mb-10" appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.company_address"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.company_address_error_msg != ''">
                                    {{contactFormValidation.company_address_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'job_title'" class="example-full-width mb-10"
                                appearance="fill">
                                <mat-label>{{form_field.form_label}}</mat-label>
                                <input type="text" matInput [formControl]="contactForm.controls.job_title"
                                    placeholder="Enter {{form_field.form_label}}">
                                <mat-hint class="error" *ngIf="contactFormValidation.job_title_error_msg != ''">
                                    {{contactFormValidation.job_title_error_msg}}
                                </mat-hint>
                            </mat-form-field>

                            <mat-form-field *ngIf="form_field.form_name == 'image'"
                                class="example-full-width mb-10 upload-file">
                                <div>
                                    <mat-toolbar>
                                        <input matInput placeholder="{{form_field.form_label}}" readonly name="name" />
                                        <button type="button" mat-flat-button>Browse File</button>
                                    </mat-toolbar>

                                    <input type="file" #fileInput id="uploadFile" (change)="uploadFileEvt($event)"
                                        name="uploadFile" accept="image/*" />
                                </div>
                                <mat-hint class="error" *ngIf="contactFormValidation.image_error_msg != ''">
                                    {{contactFormValidation.image_error_msg}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </mat-input-container>
                </div>
            </div>
        </div>

        <div mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>No Thanks</button>

            <ng-container *ngIf="this.button_name != ''">
                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                    color="{{submite_button_color}}">{{this.button_name}}</button>
            </ng-container>

            <ng-container *ngIf="this.button_name == ''">
                <button *ngIf="isButtonLoading == false" type="submit" mat-raised-button
                    color="{{submite_button_color}}">Submit</button>
            </ng-container>

            <button *ngIf="isButtonLoading == true" type="submit" mat-raised-button color="primary" disabled>Please wait...</button>
        </div>
    </form>
</div>